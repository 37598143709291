// Here you can add other styles
.img-centered {
  margin: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.image-preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.image-preview {
  width: 250px;
  height: 100%;
  margin: 10px;
  text-align: center;
  overflow: hidden;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}

.map-container {
  width: 100%;
  height: 400px;
}

.max-width-height {
  width: 100%;
  height: 100%;
}

.equal-height-width-padding {
  height: 50px;
  margin: 15px;
}

.custom-toast {
  background-color: #f5f5f5;
  color: #000;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.date-inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-date-input {
  display: flex;
  width: 45%;
  margin-right: 10%;
}

.end-date-input {
  display: flex;
  width: 45%;
}

.pagination-center {
  display: flex;
  justify-content: center;
}
.flex-parent {
  display: flex;
}
.fixed-card {
  height: 120px; /* Adjust the height as per your preference */
  padding: 0.75rem;
  overflow: auto;
}

.jc-center {
  justify-content: center;
}

.artisan-card {
  height: 600px;
}

.artisan-image-container {
  position: relative;
  height: 70%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.artisan-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1); /* Adjust the scale value to zoom in/out */
  transition: transform 0.3s ease;
}
.badge-image-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.badge-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1); /* Adjust the scale value to zoom in/out */
  transition: transform 0.3s ease;
}
.badge-card {
  height: 600px;
}

.artisan-card:hover .artisan-image {
  transform: scale(1.2); /* Reset the scale on hover */
}
.badge-card:hover .badge-image {
  transform: scale(1.2); /* Reset the scale on hover */
}
.link-no-underline {
  text-decoration: none;
  color: #000;
}

.user-info-block {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-picture {
  margin-right: 20px;
}

.user-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-details h2 {
  margin: 0;
  font-size: 1.5rem;
}

.user-details p {
  margin: 5px 0;
}
